import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero, {HeroForm} from "../components/bricks/Hero";
import {themeBright, themeDark, themePurple} from "../page-styles/landingpage.css";
import ThemedSingleFeature, {MiniQuote} from "../components/bricks/ThemedSingleFeature";
import ColorScroller from "../components/ColorScroller";
import ThemedMultiFeature from "../components/bricks/ThemedMultiFeature";
import ThemeBox from "../components/bricks/ThemeBox";
import {Box, XCol} from "../components/xui/Box";
import {ThemedButton} from "../components/xui/ThemedButton";
import {Counter} from "../components/Counter";
import {InnerCardFall} from "../components/CardFall";
import Header from "../components/Header";
import {Quote} from "../components/ui/Quote";
import Document from "../components/Document";
import useCounters from "../components/useCounters";

const CustomerLogo = ({name, imgSrc, url}) => (
  <XCol align="center" justify="center">
    <Box
      as="img"
      height="5"
      width="8"
      maxWidth="100%"
      src={imgSrc}
      alt={`${name} logo`}
      title={name}
      objectFit="contain"
    />
  </XCol>
);

const Badge = ({children}) => (
  <Box
    bg="themeAccent"
    rounded="sm"
    color="primaryButtonHighlight"
    px={2}
    size="sm"
    absolute
    top={0}
    left="100%"
    textTransform="uppercase"
    lineHeight="none"
    style={{marginLeft: 5, paddingTop: 3, paddingBottom: 3}}
  >
    {children}
  </Box>
);

const SpecialHeading = ({left, right, highlightLeft, highlightRight}) => {
  const defaultProps = {size: "xxxl", weight: "normal", color: "hint", display: "inline"};
  const higlightProps = {as: "h2", color: "primary"};
  return (
    <Box as="span" textAlign="center">
      <Box {...defaultProps} {...(highlightLeft && higlightProps)}>
        {left}{" "}
      </Box>
      <Box {...defaultProps} {...(highlightRight && higlightProps)}>
        {right}
      </Box>
    </Box>
  );
};

const IndexPage = ({location}) => {
  const counts = useCounters();

  return (
    <DefaultLayout
      description="Playful Project Management Tool for Game Developers by Game Developers. Unique trading card game inspired design. Integrated Discord community management. Sign up for free!"
      keywords="project management, game development, indie game development, gamedev, indiedev, trello alternative, jira alternative, discord, community management, game design document, playful"
      canonicalUrl="/"
      location={location}
      headerContent={
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            name: "Codecks",
            url: "https://www.codecks.io/",
            logo:
              "https://perma-assets-codecks.s3.eu-central-1.amazonaws.com/logo/logo-2019/cdx__logo--socialmedia.png",
            sameAs: [
              "https://twitter.com/codecks_io",
              "https://www.linkedin.com/company/codecks",
              "https://www.instagram.com/codecks_io/",
              "https://www.youtube.com/channel/UCVkG6keEFZOQ6AEAnLBtXQA",
              "https://www.facebook.com/codecks.io",
            ],
          })}
        </script>
      }
    >
      <Header />
      <ColorScroller.Container initial={themeDark} relative>
        <Box as={InnerCardFall} cardPer100px={5} zIndex={1} />
        <Hero
          title={<>Turning Ideas Into Games, Together.</>}
          subline={
            <>
              Project & Community Management in one.
              <br />
              Made for Game Development.
            </>
          }
          img="next/hero.png"
          noCardFall
          noHeader
        >
          <HeroForm
            placeholder="Your email"
            buttonLabel="Get Started"
            authProviderLabel="Or proceed with"
          />
        </Hero>

        <XCol sp={9}>
          <ColorScroller.ThemeBox
            withBorder
            customTitle={
              <SpecialHeading left="Your Project." right="Your Community." highlightLeft />
            }
            theme={themeDark}
            sp={10}
            innerPy={9}
          >
            <ThemedSingleFeature
              title="Created by Game Devs — for Game Devs"
              titleUrl="/features/cards-decks-and-projects/"
              description="The dedicated planning tool that feels just right. All you need in one tool with support for GDDs, tasks, milestones, asset pipelines and much more."
              img="next/decks.png"
              imgAlt="Overview of decks in a Codecks project"
            >
              <MiniQuote
                authorName="Felipe Falanghe"
                authorInfo="Kerbal Space Program"
                companyName="Floating Origin Interactive"
                img="felipe-falanghe"
              >
                Codecks is an order of magnitude above all project management solutions we've used
                before.
              </MiniQuote>
            </ThemedSingleFeature>
            <ThemedSingleFeature
              title="Playful by design"
              titleUrl="/features/hero-cards-and-journeys/"
              description="The planning tool that your WHOLE team actually enjoys using, instead of being forced to. Inspired by card games, a new playful platform that works for everybody on your team."
              img="plannable-game-design.png"
              imgAlt="Journey to automate common workflows."
              imgRight
            >
              <MiniQuote
                authorName="Colter Hochstetler"
                companyName="SunSpear Games"
                img="colter-hochstetler"
              >
                Having your game design documentation in the same tool as task management has given
                us many unforseen efficiencies.
              </MiniQuote>
            </ThemedSingleFeature>
            <ThemedSingleFeature
              title="Stay on top of your process"
              titleUrl="/features/unique-hand-design/"
              description="Track conversations and decisions instead of chasing outdated tasks. Share what you're working on now, next and in the future. Create clarity and shared team momentum without the mumbo jumbo."
              img="hand-design.png"
              imgAlt="Preview of hand feature."
            >
              <MiniQuote
                authorName="Lukas Orsvärn"
                authorInfo="Technical Artist"
                companyName="Wolfire Games"
                img="lukas-orsvarn"
              >
                Codecks solves the communication problem for distributed teams elegantly, making it
                as easy as chat, and more reliable than email.
              </MiniQuote>
            </ThemedSingleFeature>
          </ColorScroller.ThemeBox>

          <ColorScroller.ThemeBox
            withBorder
            customTitle={
              <SpecialHeading left="Your Project." right="Your Community." highlightRight />
            }
            theme={themeDark}
            sp={10}
            innerPy={9}
          >
            <ThemedSingleFeature
              title="Collect feedback from Discord"
              titleUrl="/features/discord-integration/"
              description="Collect feedback quicker and with less hassle with our Discord bot. Relieve your community manager from busy work and rally your audience with karma points, upvotes and automatic status updates."
              img="discord.png"
              imgAlt="Codecks is the first project management tool with built-in Discord integration into its core"
              imgRight
            >
              <Counter count={counts.discord_commands} label="Discord Commands Received" />
            </ThemedSingleFeature>
            <ThemedSingleFeature
              title="Collect feedback from the Web"
              titleUrl="/features/open-development/"
              description="Capture the attention of your fans even before launching your game, while doing what you're doing anyway. Collect subscribers to your open decks roadmap and get feedback on your ideas in real-time."
              img="open-development.png"
              imgAlt="Preview of a public page containing decks."
            >
              <Counter count={counts.upvotes} label="Fan Upvotes Received" />
            </ThemedSingleFeature>
            <ThemedSingleFeature
              title="Collect feedback from your Game"
              description={
                <>
                  Collect bugs directly from your Unity or Unreal game and let fans automatically
                  know when you fixed their bug, making them come back and become super fans. Or
                  build your own integration using our powerful{" "}
                  <Box
                    as="a"
                    color="buttonSecondary"
                    bold
                    hover_color="purple600"
                    href="https://manual.codecks.io/api/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    API
                  </Box>
                  .
                </>
              }
              img="game-engine-integration.png"
              imgAlt="Card generated from an in-game user report."
              imgRight
            >
              <Counter count={counts.user_reports} label="Bugs Collected" />
            </ThemedSingleFeature>
          </ColorScroller.ThemeBox>
        </XCol>
      </ColorScroller.Container>
      <ThemeBox theme={themePurple}>
        <ThemedMultiFeature
          features={[
            {
              title: "Real-time conversations replaces chat",
              titleUrl: "/features/conversations-and-notifications/",
              img: "next/notifications.png",
              description:
                "Don't bury important information in your fleeting chat rooms. Automatic nudges makes sure that conversations are resolved quickly.",
            },
            {
              title: "Deliver on time",
              titleUrl: "/features/milestones/",
              img: "timeline/timeline.png",
              description:
                "Build a steady rhythm, keep your team focused, and avoid crunch with Milestones and Runs.",
            },
            {
              title: "Integrated Time-tracking",
              titleUrl: "/features/time-tracking/",
              img: "time-tracking-intro.png",
              description:
                "Time Tracking built-in, so you don't need a separate tool. Track time for every card and quickly create reports.",
            },
            {
              title: "Dependencies",
              img: "next/dependencies.png",
              description:
                "Track relations between cards and inform your team automatically when their task is ready for action.",
            },
            {
              title: "Great Multi Project Control",
              titleUrl: "/features/cards-decks-and-projects/",
              img: "next/multi-project.png",
              description:
                "Allows you to stay on top of multiple productions. Laser focus on one project or effortlessly see all projects at a glance.",
            },
            {
              title: "Powerful integrations",
              titleUrl: "/features/source-control-integration/",
              img: "next/integration.png",
              description:
                "Powerful integrations and importers so that you can bring your favorite dev tools with you.",
            },
          ]}
        />
        <XCol align="center">
          <ThemedButton to="/features/">Explore more Features</ThemedButton>
        </XCol>
      </ThemeBox>
      <ThemeBox title="Trusted by Great Game Companies" bg="brightGradient" theme={themeBright}>
        <Box grid gridColumns={4} sp={7} md_gridColumns={2}>
          <CustomerLogo
            name="Unity"
            imgSrc={require("../imgs/customers/unity.png")}
            url="https://unity.com/"
          />

          <CustomerLogo
            name="Wolfire Games"
            imgSrc={require("../imgs/customers/wolfire.png")}
            url="https://www.wolfire.com/"
          />

          <CustomerLogo
            name="btf"
            imgSrc={require("../imgs/customers/btf.png")}
            url="https://btf.de//"
          />

          <CustomerLogo
            name="Paintbucket Games"
            imgSrc={require("../imgs/customers/paintbucket.png")}
            url="https://paintbucket.de/en"
          />

          <CustomerLogo
            name="Chasing Carrots"
            imgSrc={require("../imgs/customers/chasing-carrots.png")}
            url="https://www.chasing-carrots.com/"
          />

          <CustomerLogo
            name="Grimbart Tales"
            imgSrc={require("../imgs/customers/grimbart-tales.png")}
            url="https://www.grimbart-tales.com/"
          />

          <CustomerLogo
            name="Chance Agency"
            imgSrc={require("../imgs/customers/chance-agency.png")}
            url="http://www.chanceagency.com/"
          />

          <CustomerLogo
            name="Maschinen-Mensch"
            imgSrc={require("../imgs/customers/mm.png")}
            url="http://maschinen-mensch.com/"
          />
        </Box>
        <XCol align="center">
          <ThemedButton to="/showcase/">Show Project Showcase</ThemedButton>
        </XCol>
        <XCol align="center">
          <Box grid gridColumns={4} sp={6} lg_gridColumns={2} sm_gridColumns={1}>
            <XCol sp={7} pt={7} lg_pb={7} sm_pt={0} sm_pb={0}>
              <Quote
                authorName="Ciro Continisio"
                companyName="Unity Technologies"
                img="ciro-continisio"
              >
                We love Codecks! We use it to manage tasks for ourselves, but the ability to expose
                these to the community was a key feature for the public-facing nature of our
                project.
              </Quote>
              <Quote
                authorName="Felipe Falanghe"
                authorInfo="Creator of Kerbal Space Program"
                companyName="Floating Origin Interactive"
                img="felipe-falanghe"
              >
                Codecks is an order of magnitude above all project management solutions we've used
                before. I've used other integrated tools before, but this takes it to a whole new
                level.
              </Quote>
              <Quote
                authorName="Colter Hochstetler"
                authorInfo="Founder & Director of Game"
                companyName="SunSpear Games"
                img="colter-hochstetler"
              >
                Having your game design documentation in the same tool as task management has given
                us many unforseen efficiencies. Codeck's is a more intuitive design documentation
                tool than documents or wiki.
              </Quote>
            </XCol>
            <XCol sp={7} lg_pb={7} sm_pb={0}>
              <Quote
                authorName="Lukas Orsvärn"
                authorInfo="Technical Artist"
                companyName="Wolfire Games"
                img="lukas-orsvarn"
              >
                Codecks solves the communication problem for distributed teams elegantly, making it
                as easy as chat, and more reliable than email. We have fewer meetings as a result,
                and everyone is really happy with how snappy and fun it is to use!
              </Quote>
              <Quote
                authorName="Daniel Helbig"
                companyName="Megagon Industries"
                img="daniel-helbig"
              >
                We tried a lot of different game project management tools but Codecks is the first
                one we stuck with and it's now an integral part of our daily routine and planning.
              </Quote>
              <Quote authorName="Paul Lawitzki" companyName="Chasing Carrots" img="paul-lawitizki">
                We've tried a lot of agile PM solutions in the past. With Codecks it seems like
                we've found our final best fit. It's lean and easy to get into. Yet it's powerful
                and satisfies all our needs regarding agile project management.
              </Quote>
            </XCol>
            <XCol sp={7} pt={7} sm_pt={0}>
              <Quote
                authorName="Ata Sergey Nowak"
                authorInfo="Managing Director & Co-founder"
                companyName="Torpor Games"
                img="ata-sergey-nowak"
              >
                We have used Codecks during the development of Suzerain extensively and it provided
                a visually pleasing and efficient game project management experience.
              </Quote>
              <Quote authorName="Sam Luckhardt" companyName="Tonkotsu Games" img="sam-luckhardt">
                If you've ever felt like falling asleep while using Jira, Trello or writing the
                451st user story, this is the PJM tool for you. Elegant, responsive &amp; more fun
                than shuffling sleeved cards.
              </Quote>
              <Quote authorName="Milan Pingel" companyName="Massive Miniteam" img="milan-pingel">
                The adaptive design and visual card ordering is easy to get into and perfect for
                keeping track of multiple projects at once. Also a big bonus: the direct feedback
                loop for bugs and feature requests with the developers - our wishes are being heard
                and responded to.
              </Quote>
            </XCol>
            <XCol sp={7}>
              <Quote authorName="Jörg Reisig" companyName="Anomaly Games" img="joerg-reisig">
                Codecks is playful, easy to use and well arranged. I can manage tasks detailed
                enough to work efficiently but I do not lose any time on juggling with the project
                management software.
              </Quote>
              <Quote authorName="Sebastian Krause" companyName="Batty Rabbit" img="batty-rabbit">
                It is super joyful to use Codecks! In other tools a lot of time was spent in the
                tool rather than working on problems. Codecks makes it super easy to change stuff
                around and super useful to keep track of things like metrics, blocks, reviews and
                multiple conversation threads.
              </Quote>
              <Quote authorName="Alex May" companyName="Moon Mode" img="alex-may">
                It's fairly obvious that it's created by game developers - there's clearly been a
                lot of thought put into what works and what doesn't.
              </Quote>
            </XCol>
          </Box>
        </XCol>
        <XCol align="center" sp={7} pt={6}>
          <Box
            px={6}
            py={5}
            bg="bgBack"
            color="body"
            className={themeBright}
            elevation="lg"
            rounded="md"
            maxWidth="lg"
          >
            <Document>
              <p>Dear Game Developer,</p>
              <p>
                project management tools are notoriously unsatisfying to use. We found all the tools
                that we tried to be either too complex or too simplistic.{" "}
                <b>
                  We believe that gamedevs deserve their own production tool that is just right for
                  them.
                </b>
              </p>
              <p>
                Codecks is a project by the indie game company{" "}
                <b>
                  <a href="http://maschinen-mensch.com" target="_blank" rel="noreferrer">
                    Maschinen-Mensch
                  </a>
                </b>{" "}
                and some extraordinarily skilled web developers. We've been working on it since 2019
                and believe that we've created something special:
              </p>
              <p>
                A tool that aims at just the right amount of complexity and is able to excite
                creative visual thinkers and productivity fans alike. Any task planning tool gets
                more useful if everybody on the team is using it and that's why{" "}
                <b>joyful interaction</b> is at the core of Codecks instead of an afterthought.
              </p>
              <p>
                <b>Codecks is our love letter to the people who make games.</b>
              </p>
              <p>
                <span role="img" aria-label="heart">
                  💜
                </span>
                , <em>the Codecks Team</em>
              </p>
            </Document>
          </Box>
        </XCol>
      </ThemeBox>
    </DefaultLayout>
  );
};

export default IndexPage;
