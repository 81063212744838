import React from "react";
import FeatureImage from "./FeatureImage";
import {Box, XCol, XRow} from "../xui/Box";
import {ThemedButton} from "../xui/ThemedButton";
import {Link} from "gatsby";
import Icon from "../Icon";

const WrapLinkInclButton = ({titleUrl, children}) =>
  titleUrl ? (
    <XRow align="center" sp={1} width="100%" triggerButtonHover>
      <Link to={titleUrl}>{children}</Link>
      <Box ml="auto">
        <ThemedButton to={titleUrl} size="xsSquare">
          <Icon.ArrowRight />
        </ThemedButton>
      </Box>
    </XRow>
  ) : (
    children
  );

const Content = ({title, titleUrl, description, button, lead}) => (
  <XCol sp={5} fillParent>
    <XCol sp={2}>
      {lead && (
        <Box size="md" color="accent" lineHeight="wide">
          {lead}
        </Box>
      )}
      <WrapLinkInclButton titleUrl={titleUrl}>
        <Box as="h3" weight="normal" size="xl" color="secondary" hover_color={titleUrl && "accent"}>
          {title}
        </Box>
      </WrapLinkInclButton>
    </XCol>
    {description && (
      <Box as="p" size="lg" color="body" lineHeight="wide">
        {description}
      </Box>
    )}
    {button && (
      <>
        <XCol align="start" mt="auto">
          <ThemedButton to={button.url}>{button.label}</ThemedButton>
        </XCol>
      </>
    )}
  </XCol>
);

const chunkArray = (arr, chunkSize) => {
  const results = [];
  let i = 0;
  while (i < arr.length) {
    results.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return results;
};

const fillArr = len => {
  return Array.from(new Array(len), () => null);
};

const ThemedMultiFeature = ({features, maxColumns = 3}) => {
  const groups = chunkArray(features, maxColumns);
  const len = groups[0].length;
  return (
    <XCol align="center" sp={5}>
      {groups.map((group, ig) => (
        <Box
          grid
          sp={5}
          gridColumns={len}
          lg_gridColumns={1}
          key={ig}
          pt={ig > 0 ? 7 : 0}
          lg_pt={0}
        >
          {group.map(({img, button, titleUrl}, i) => (
            <XCol
              {...(titleUrl && {as: Link, to: titleUrl})}
              key={`i${ig}:${i}`}
              lg_order={i * 2}
              justify="end"
              align="center"
              width="100%"
              maxWidth="md"
              ml="auto"
              mr="auto"
            >
              <FeatureImage img={img} to={button && button.url} fade="fromTop" fadeDelay={i} />
            </XCol>
          ))}
          {fillArr(len - group.length).map((k, i) => (
            <div key={i} />
          ))}
          {group.map((feature, i) => (
            <XCol
              key={`f${ig}:${i}`}
              lg_order={i * 2 + 1}
              lg_pb={7}
              maxWidth="md"
              width="100%"
              ml="auto"
              mr="auto"
            >
              <Content {...feature} />
            </XCol>
          ))}
          {fillArr(len - group.length).map((k, i) => (
            <div key={i} />
          ))}
        </Box>
      ))}
    </XCol>
  );
};

export default ThemedMultiFeature;
