import {useEffect, useState} from "react";
import cdxFetch from "../lib/fetch";

let _cached = null;

const useCounters = () => {
  const [state, setState] = useState(
    _cached || {
      discord_commands: 30224,
      upvotes: 15707,
      user_reports: 7816,
      discord_members: [],
    }
  );
  useEffect(() => {
    if (_cached) return;
    cdxFetch(`/services/lp-counters`).then(r =>
      setState(prev => {
        const next = r.reduce(
          (m, r) => {
            m[r.key] = r.value || prev[r.key];
            return m;
          },
          {...prev}
        );
        _cached = next;
        return next;
      })
    );
  }, []);
  return state;
};

export default useCounters;
