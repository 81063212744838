import React, {useState, useEffect, useRef, useCallback, createElement, Fragment} from "react";
import {createObserver} from "./ColorScroller";
import {Box, XRow} from "./xui/Box";

const formatNum = num => {
  if (num < 1000) return num.toString();
  let parts = [];
  let n = num;
  while (parts.length < 3) {
    parts.unshift((n % 1000).toString());
    n = Math.floor(n / 1000);
    if (!n) break;
    parts[0] = parts[0].padStart(3, "0");
  }
  return parts.join(",");
};

const hasIntersectionObserver = typeof IntersectionObserver !== "undefined";

const useCount = targetCount => {
  const [count, setCount] = useState(hasIntersectionObserver ? 0 : targetCount);
  const [state, setState] = useState(hasIntersectionObserver ? "initial" : "done");
  const isCounting = state === "counting";
  const targetRef = useRef();
  useEffect(() => {
    targetRef.current = targetCount;
  }, [targetCount]);

  useEffect(() => {
    if (!isCounting) return;
    let id;
    let speed = targetRef.current / 25;
    const nextFrame = () => {
      id = requestAnimationFrame(() => {
        setCount(c => {
          const nextCount = Math.min(targetRef.current, c + speed);
          speed *= 0.97;
          if (nextCount !== targetRef.current) nextFrame();
          return nextCount;
        });
      });
    };

    nextFrame();
    return () => cancelAnimationFrame(id);
  }, [isCounting]);

  const startCounting = useCallback(() => {
    setState(s => (s === "initial" ? "counting" : s));
  }, []);
  return [Math.floor(count), startCounting];
};

const fill = (content, length) => {
  if (content.length >= length) return content;
  return createElement(
    Fragment,
    {},
    ...[...Array(length - content.length).fill(<>&nbsp;</>), content]
  );
};

export const Counter = ({count: targetCount, label}) => {
  const [shownNumber, startCounting] = useCount(targetCount);
  const [node, setNode] = useState(null);
  useEffect(() => {
    if (node) {
      return createObserver({node, onIntersect: startCounting});
    }
  }, [node, startCounting]);

  return (
    <XRow align="center" ref={setNode} sp={4}>
      <Box
        px={5}
        py={3}
        size="xxl"
        bold
        borderWidth={1}
        borderColor="counter"
        color="counter"
        rounded="full"
        numeric
        elevation="sm"
      >
        {fill(formatNum(shownNumber), formatNum(targetCount).length)}
      </Box>
      <Box color="secondary">{label}</Box>
    </XRow>
  );
};
