import React from "react";
import Avatar from "../Avatar";
import {Box, XCol, XRow} from "../xui/Box";

const DownArrow = () => (
  <Box absolute top="100%" left="1">
    <Box as="svg" forwardProps={{width: 17, height: 13, fill: "none"}} display="block">
      <Box as="path" d="M16.5 0H0.5L8.5 11.5L16.5 0Z" svgFill="bgBack" />
      <Box as="path" d="M0 0.5H1L8.5 12L16 0.5H17" svgStroke="blue" strokeLinejoin="round" />
    </Box>
  </Box>
);

export const Quote = ({children, authorName, authorInfo, companyName, img, width = "sm"}) => (
  <XCol align="center">
    <XCol sp={5}>
      <XCol
        bg="bgBack"
        pa={4}
        rounded="lg"
        sp={5}
        borderWidth={1}
        borderColor="blue"
        elevation="lg"
        maxWidth={width}
        relative
      >
        <Box as="p" color="body" size="md" lineHeight="wide">
          {children}
        </Box>
        <DownArrow />
      </XCol>
      <XRow sp={4} pl={1} align="center">
        {img && <Avatar name={img} nonGray />}
        <XCol sp={1}>
          <Box color="body" size="sm" bold>
            {authorName}
          </Box>
          <Box color="purple400" size="xs">
            {authorInfo ? `${authorInfo} / ` : ""}
            {companyName}
          </Box>
        </XCol>
      </XRow>
    </XCol>
  </XCol>
);
